
import { defineComponent, ref, onMounted } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
import { intlFormat } from '@/utils/currencyFunctions'
import { serviceTypes } from '@shared/*'

export default defineComponent({
  name: 'Team',
  components: {
    TableLayout,
    TableTile,
    CustomButton,
    DashboardSection,
    NoResult
  },
  setup() {
    const roleList = ref<serviceTypes.ServiceResponse[]>([])
    async function getRoles() {
      roleList.value = await authApi.getProjectRoles()
    }
    onMounted(getRoles)
    // async function deleteRole(id: number) {
    //   await authApi.deleteProjectRole(id).then(function () {
    //     const subIndex = roleList.value.findIndex((e: any) => e.id === id)
    //     if (subIndex !== -1) {
    //       roleList.value.splice(subIndex, 1)
    //     }
    //   })
    // }
    const formatCurrencies = (value: number) => intlFormat(value)
    return { roleList, formatCurrencies }
  }
})
