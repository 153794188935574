<template>
  <div class="roles-wrapper" v-if="roleList && roleList.length > 0">
    <DashboardSection>
      <template #top-right-button>
        <CustomButton
          @onClick="$router.push({ name: 'AddRole' })"
          iconClass="plus"
          label="New studio role"
          color="grey"
          size="small"
        />
      </template>
      <template #content>
        <TableLayout>
          <template #left-headings>
            <p class="left-heading-item"><strong>Role</strong></p>
            <p class="left-heading-item"><strong>Hourly rate</strong></p>
          </template>
          <template #right-headings>
            <p class="right-heading-item mobile-hide"><strong>Annual salary + benefits</strong></p>
          </template>
          <TableTile v-for="item in roleList" :key="item.id">
            <template #tileContent>
              <div>
                <div class="left-content">
                  <p>{{ item.name }}</p>
                  <p class="grey-value">{{ formatCurrencies(+item.rate) }}</p>
                </div>
                <div class="right-content">
                  <p class="grey-value mobile-hide">
                    {{ formatCurrencies(+item.medianFullTimeSalary) }}
                  </p>
                </div>
              </div>
            </template>
            <template #menu>
              <ul>
                <li @click="$router.push({ name: 'EditRole', params: { id: item.id } })">Edit</li>
                <!-- <li @click="deleteRole(item.id)">Delete</li> -->
              </ul>
            </template>
          </TableTile>
        </TableLayout>
      </template>
    </DashboardSection>
  </div>
  <div class="no-documents" v-else>
    <NoResult title="You haven't added any project roles yet">
      <CustomButton @click="$router.push({ name: 'AddRole' })" label="Add new project role" color="blue" />
    </NoResult>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
import { intlFormat } from '@/utils/currencyFunctions'
import { serviceTypes } from '@shared/*'

export default defineComponent({
  name: 'Team',
  components: {
    TableLayout,
    TableTile,
    CustomButton,
    DashboardSection,
    NoResult
  },
  setup() {
    const roleList = ref<serviceTypes.ServiceResponse[]>([])
    async function getRoles() {
      roleList.value = await authApi.getProjectRoles()
    }
    onMounted(getRoles)
    // async function deleteRole(id: number) {
    //   await authApi.deleteProjectRole(id).then(function () {
    //     const subIndex = roleList.value.findIndex((e: any) => e.id === id)
    //     if (subIndex !== -1) {
    //       roleList.value.splice(subIndex, 1)
    //     }
    //   })
    // }
    const formatCurrencies = (value: number) => intlFormat(value)
    return { roleList, formatCurrencies }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
.roles-wrapper
  .roles-row
    display: flex
    flex-wrap: wrap
    & > *
      margin-right: 1rem
      margin-bottom: 1rem
    .grey-value
      color: map-get($greyShades,greyShade60)
      font-size: 0.875rem
      margin-top: 1rem
    @media( max-width: $md-breakpoint)
      flex-direction: column
    .heading
      font-weight: bold
  .role-tile
    text-decoration: none
  $leftWidth: 12rem
  $rightWidth: 5rem
  .tile-tile
    font-weight: bold
    font-size: 16px
  .tile-subtitle
    font-size: 12px
    color: map-get($greyShades, 'greyShade80')
  .left-content, .right-content
    display: flex
    align-items: center
    margin-left: 0rem
    & > *
      text-align: center
      word-break: break-all
  .left-content > *
    width: $leftWidth
    text-align: left
  .right-content > *
    min-width: $rightWidth
  .right-heading-item
    min-width: $rightWidth
  .left-heading-item
    width: $leftWidth
    text-align: left
    &:first-child
      padding-left: 1rem
  .mobile-hide
    @media( max-width: $md-breakpoint)
      display: none
</style>
